"use client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScrewdriverWrench } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";

type IProps = {
  message: string;
  code: number;
  details?: string;
};

export default function ErrorMessage({ message, code, details }: IProps) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="grid h-screen place-content-center bg-white px-10 md:px-4">
      <div className="flex flex-col items-center text-center">
        <h1 className="my-0 text-[20rem] uppercase text-gray-200">
          <FontAwesomeIcon icon={faScrewdriverWrench} className="mr-10 hidden h-[12rem] md:block" />
          {code}
        </h1>
        <span className="text-[3.4rem] font-bold tracking-tight text-primary">{message}</span>
        <span className="mt-2 text-[1.6rem] text-gray-500">
          But don&apos;t worry, there are plenty of other things
          <br className="hidden md:block" />
          you can find from our homepage.
        </span>
        {details && (
          <div tabIndex={0} className={`collapse mt-2 text-[1.4rem] text-error ${showDetails ? "collapse-open" : ""}`}>
            <div className="collapse-content">
              <p>{details}</p>
            </div>
          </div>
        )}
        <div className="flex gap-2">
          {details && (
            <span className="btn btn-neutral btn-outline btn-lg mt-10 " onClick={() => setShowDetails(!showDetails)}>
              {!showDetails ? "Show" : "Hide"} Details
            </span>
          )}
          <a href={process.env.NEXT_PUBLIC_KITSET_URL} className="btn btn-neutral btn-lg mt-10 text-white">
            Go Back Home
          </a>
        </div>
      </div>
    </div>
  );
}
